import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from '../components/seo'
import {DangerousHTML} from '../components/contentful/html'
import Film from '../components/contentful/film'

const BrandingAndMarketingPage = ({data}) => (
	<Layout>
		<Helmet>
			<body className="branding-and-marketing-page" />
		</Helmet>
		<SEO {...data.page?.metadata} />
		<div className="inset">
			<DangerousHTML>{data.page?.body.html.content}</DangerousHTML>
		</div>
		{data.commercials.items.map(film => (
			<Film key={film.id} {...film} />
		))}
	</Layout>
)

export default BrandingAndMarketingPage

export const query = graphql`
	{
		page: contentfulBasicPage(title: {eq: "Branding and Marketing"}) {
			body {
				html: childMarkdownRemark {
					content: html
				}
			}
			metadata {
				...ContentfulMetadataFragment
			}
		}
		commercials: contentfulList(name: {eq: "Branding Page Commercials"}) {
			items {
				... on ContentfulFilm {
					...ContentfulFilmFragment
					id
				}
			}
		}
	}
`
